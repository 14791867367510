import React from "react";
import Container from "../components/Container/Container";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/SEO/SEO";
import ContactForm from "../components/Global/ContactForm";
import PortableText from "react-portable-text";
import { graphql, Link } from "gatsby";

export const query = graphql`
	query ContactUsQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			defaultImage {
				asset {
					_id
				}
			}
		}
		sanitySiteSettings {
			phone
			email
			_rawAddress
		}
		sanityContactUs {
			title
			description
			leftTitle
			leftDescription
			formTitle
		}
	}
`;

const ContactUs = (props) => {
	const { data, errors } = props;
	const site = (data || {}).site;

	if (errors) {
		return (
			<>
				<GraphQLErrorList errors={errors} />
			</>
		);
	}

	return (
		<>
			<Seo
				title={data.sanityContactUs.title}
				description={data.sanityContactUs.description}
				image={site.defaultImage}
			/>
			<Container>
				<div className="bg-off-white">
					{/* Intro Title Block */}
					
					<div className="border-b border-grey-default">
						<div className="flex flex-col md:grid md:grid-cols-12 gap-[56px] md:gap-0 min-h-[calc(100vh-73px)]">
							<div className="md:col-span-6 pt-[40px] px-[24px] md:p-[40px] border-r border-grey-default flex flex-col">
								<h2 className="text-[18px] flex-grow font-normal mb-3">{data.sanityContactUs.leftTitle}</h2>
								
								<div className="max-w-[540px] font-serif text-[32px]/[1.2]">
									{data.sanityContactUs.leftDescription}	
								</div>
							</div>
							<div className="mt-2 pb-[40px] px-[24px] md:mt-0 md:p-[40px] md:col-span-6">
								<h1 className="text-[18px] mb-[40px] font-normal">
									{data.sanityContactUs.formTitle}
								</h1>

								<div className="grid gap-[24px] lg:grid-cols-2 mb-[40px] text-[14px]/[24px] font-normal">
									<div>
										<div className="flex items-center gap-[16px] mb-[24px]">
											<div>
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_2859_5300)">
														<path d="M5.58685 5.90223C6.05085 6.86865 6.68337 7.77441 7.48443 8.57546C8.28548 9.37651 9.19124 10.009 10.1577 10.473C10.2408 10.5129 10.2823 10.5329 10.3349 10.5482C10.5218 10.6027 10.7513 10.5636 10.9096 10.4502C10.9542 10.4183 10.9923 10.3802 11.0685 10.304C11.3016 10.071 11.4181 9.95443 11.5353 9.87824C11.9772 9.59091 12.5469 9.59091 12.9889 9.87824C13.106 9.95443 13.2226 10.071 13.4556 10.304L13.5856 10.4339C13.9398 10.7882 14.117 10.9654 14.2132 11.1556C14.4046 11.534 14.4046 11.9809 14.2132 12.3592C14.117 12.5495 13.9399 12.7266 13.5856 13.0809L13.4805 13.186C13.1274 13.5391 12.9508 13.7156 12.7108 13.8505C12.4445 14.0001 12.0308 14.1077 11.7253 14.1068C11.45 14.1059 11.2619 14.0525 10.8856 13.9457C8.86333 13.3718 6.95509 12.2888 5.36311 10.6968C3.77112 9.10479 2.68814 7.19655 2.11416 5.17429C2.00735 4.79799 1.95395 4.60984 1.95313 4.33455C1.95222 4.02906 2.0598 3.6154 2.20941 3.34907C2.34424 3.10904 2.52078 2.9325 2.87386 2.57942L2.97895 2.47433C3.33325 2.12004 3.5104 1.94289 3.70065 1.84666C4.07903 1.65528 4.52587 1.65528 4.90424 1.84666C5.0945 1.94289 5.27164 2.12004 5.62594 2.47433L5.75585 2.60424C5.98892 2.83732 6.10546 2.95385 6.18165 3.07104C6.46898 3.51296 6.46898 4.08268 6.18165 4.52461C6.10546 4.6418 5.98892 4.75833 5.75585 4.9914C5.67964 5.06761 5.64154 5.10571 5.60965 5.15026C5.4963 5.30854 5.45717 5.53805 5.51165 5.72495C5.52698 5.77754 5.54694 5.81911 5.58685 5.90223Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
													</g>
													<defs>
														<clipPath id="clip0_2859_5300">
															<rect width="16" height="16" fill="white"/>
														</clipPath>
													</defs>
												</svg>

											</div>
											<div>
												<a href={'tel:' + data.sanitySiteSettings.phone.replace(' ', '') }>{data.sanitySiteSettings.phone}</a>
											</div>
										</div>
										<div className="flex items-center gap-[16px]">
											<div>
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1.33398 4.66602L6.77726 8.47631C7.21804 8.78486 7.43844 8.93913 7.67816 8.99889C7.88991 9.05167 8.11139 9.05167 8.32314 8.99889C8.56287 8.93913 8.78326 8.78486 9.22404 8.47631L14.6673 4.66602M4.53398 13.3327H11.4673C12.5874 13.3327 13.1475 13.3327 13.5753 13.1147C13.9516 12.9229 14.2576 12.617 14.4493 12.2407C14.6673 11.8128 14.6673 11.2528 14.6673 10.1327V5.86602C14.6673 4.74591 14.6673 4.18586 14.4493 3.75803C14.2576 3.38171 13.9516 3.07575 13.5753 2.884C13.1475 2.66602 12.5874 2.66602 11.4673 2.66602H4.53398C3.41388 2.66602 2.85383 2.66602 2.426 2.884C2.04968 3.07575 1.74372 3.38171 1.55197 3.75803C1.33398 4.18586 1.33398 4.74591 1.33398 5.86602V10.1327C1.33398 11.2528 1.33398 11.8128 1.55197 12.2407C1.74372 12.617 2.04968 12.9229 2.426 13.1147C2.85383 13.3327 3.41388 13.3327 4.53398 13.3327Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>

											</div>
											<div>
												<a href={'mailto:' + data.sanitySiteSettings.email }>{data.sanitySiteSettings.email}</a>
											</div>
										</div>
									</div>
									<div>
										<div className="flex gap-[16px] items-start">
											<div className="pt-[4px]">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M7.99935 8.66732C9.10392 8.66732 9.99935 7.77189 9.99935 6.66732C9.99935 5.56275 9.10392 4.66732 7.99935 4.66732C6.89478 4.66732 5.99935 5.56275 5.99935 6.66732C5.99935 7.77189 6.89478 8.66732 7.99935 8.66732Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M7.99935 14.6673C10.666 12.0007 13.3327 9.61284 13.3327 6.66732C13.3327 3.7218 10.9449 1.33398 7.99935 1.33398C5.05383 1.33398 2.66602 3.7218 2.66602 6.66732C2.66602 9.61284 5.33268 12.0007 7.99935 14.6673Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</div>
											<div>
												<PortableText
													content={ data.sanitySiteSettings._rawAddress}
													serializers={{
														internalLink: ({ reference, children }) => {
															const href = `/${reference._type}/${reference.slug.current}`;
															return <Link to={href}>{children}</Link>;
														},
														link: ({ href, blank, children }) => {
															return (
																<a
																	href={href}
																	target={blank ? "_blank" : "_self"}
																	rel={blank ? "noreferrer" : undefined}
																>
																	{children}
																</a>
															);
														},
													}}
												/>
											</div>
										</div>
										
									</div>
								</div>

								<ContactForm />
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default ContactUs;